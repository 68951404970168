import { Icon, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import DropDown from "components/Dropdown";
import { LeaveList } from "models/leave.data";
import { TableData } from "./type";
import moment, { Moment } from "moment";
import { returnSuffix } from "common/Utils.tsx/Utils";
import { SummaryDetails } from "models/dashboard.data";
import text from "text";

const actions: any[] = [
  {
    key: text.APPROVE,
    value: text.APPROVE,
    status: text.AUTHORIZED,
  },
  {
    key: text.REJECT,
    value: text.REJECT,
    status: text.REJECTED,
  },
];

const getActions = (action: any) => {
  const actionList = actions.filter(item => {
    return item.status !== action;
  });
  return actionList;
};

const isActionAlreadyTaken = (action: string) => {
  let isActionTaken = true;
  if(action === "PAUT" || action === "PATC"){
    isActionTaken=false;
  }
  return isActionTaken;
};

const updateDuration = (
  fromDate: Moment,
  toDate: Moment,
  inSession: string,
  outSession: string
) => {
  const FIRST_SESSION_CODE = "MSES";
  const SECOND_SESSION_CODE = "NSES";

  const diff = toDate.diff(fromDate, "days");
  if (inSession === SECOND_SESSION_CODE && outSession === FIRST_SESSION_CODE) {
    return diff;
  } else if (
    (inSession === FIRST_SESSION_CODE && outSession === FIRST_SESSION_CODE) ||
    (inSession === SECOND_SESSION_CODE && outSession === SECOND_SESSION_CODE) ||
    inSession === SECOND_SESSION_CODE ||
    outSession === FIRST_SESSION_CODE
  ) {
    return diff + 0.5;
  } else {
    return diff + 1;
  }
};

const getStatusDescription = (statusCode: string) => {
  let statusDesc = "";
  switch (statusCode) {
    case "AUTH": statusDesc = text.AUTHORIZED; break;
    case "PAUT" : statusDesc = text.PENDING; break;
    case "REJE" : statusDesc = text.REJECTED; break;
    case "CAN" :
    case "PATC" : statusDesc = text.CANCELLED;
  }
  return statusDesc;
};

export function manipulateData(data: LeaveList[]) {
  const manipulatedData: TableData[] = [];
  data.map((innerRow: LeaveList, innerIndex: number) => {
    const helperArray: TableData = {
      key: 0,
      associateName: "",
      leaveType: "",
      toDate: "",
      fromDate: "",
      noOfDays: "",
      onLeave: "",
      exception: "",
      status: "",
      action: "",
      file:"",
      leaveApplicationNo:"",
      requestorEmployeeCode:"",
      requestorEmployeeName:"",
      leaveFromDate:"",
      leaveFromSessionCode:"",
      leaveFromSessionDescription:"",
      LeaveToDate:"",
      leaveToSessionCode:"",
      leaveToSessionDescription:"",
      leaveTypeCode:"",
      leaveTypeDescription:"",
      additionalReason:"",
      statusCode: "",
    };

    const fromDate = moment(innerRow.leaveFromDate)
      .format("DD MMM YY, h:mm a")
      .split(" ");

    const toDate = moment(innerRow.LeaveToDate)
      .format("DD MMM YY, h:mm a")
      .split(" ");

    const getToDate =
      toDate[0] +
      returnSuffix(toDate[0]) +
      toDate[1].slice(0, 3).toUpperCase() +
      "'" +
      toDate[2].split(",")[0];

    const getFromDate =
      fromDate[0] +
      returnSuffix(fromDate[0]) +
      fromDate[1].slice(0, 3).toUpperCase() +
      "'" +
      fromDate[2].split(",")[0];

    const dayString: string =
      moment(innerRow.LeaveToDate).diff(
        moment(innerRow.leaveFromDate),
        "days",
      ) > 1
        ? " Days "
        : " Day ";

    helperArray.key = innerIndex;
    helperArray.associateName = (innerRow.requestorEmployeeName as string).replace(
      /\w\S*/g,
      function(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
    helperArray.leaveType = (innerRow.leaveTypeDescription as string).replace(
      /\w\S*/g,
      function(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
    helperArray.fromDate = `${getFromDate} (${getSession(
      innerRow.leaveFromSessionCode as string,
    )})`;
    helperArray.toDate = `${getToDate} (${getSession(
      innerRow.leaveToSessionCode as string,
    )})`;
    const numOfDays = updateDuration(
      moment(innerRow.leaveFromDate),
      moment(innerRow.LeaveToDate),
      innerRow.leaveFromSessionCode as string,
      innerRow.leaveToSessionCode as string,
    );
    helperArray.noOfDays = `${numOfDays} ${numOfDays > 1 ? "days" : "day"}`;

    helperArray.onLeave = "A";
    helperArray.exception = innerRow.rejectionReason as string;
    helperArray.status = getStatusDescription(innerRow.leaveStatusCode as string);
    helperArray.file=innerRow.customOutput1 as string;
    helperArray.leaveApplicationNo=innerRow.leaveApplicationNo as string;
    helperArray.leaveFromDate=innerRow.leaveFromDate as string;
    helperArray.leaveFromSessionCode=innerRow.leaveFromSessionCode as string;
    helperArray.leaveFromSessionDescription=innerRow.leaveFromSessionDescription as string;
    helperArray.LeaveToDate=innerRow.LeaveToDate as string;
    helperArray.leaveToSessionCode=innerRow.leaveToSessionCode as string;
    helperArray.leaveToSessionDescription=innerRow.leaveToSessionDescription as string;
    helperArray.leaveTypeCode= innerRow.leaveTypeCode as string;
    helperArray.leaveTypeDescription=innerRow.leaveTypeDescription as string;
    helperArray.requestorEmployeeCode=innerRow.requestorEmployeeCode as string;
    helperArray.requestorEmployeeName=innerRow.requestorEmployeeName as string;
    helperArray.additionalReason=innerRow.additionalReason as string;
    helperArray.statusCode = innerRow.leaveStatusCode as string;
    manipulatedData.push(helperArray);
  });
  return manipulatedData;
}

export const getSession = (sessionCode: string) => {
  switch (sessionCode) {
    case text.FIRST_SESSION:
      return text.FIRST_SESSION_DESC;
    case text.SECOND_SESSION:
      return text.SECOND_SESSION_DESC;
    default:
      return text.WHOLE_DAY_DESC;
  }
};

const calculateSession = (startLeaveCode: string, endLeaveCode: string) => {
  if (
    (startLeaveCode === text.FIRST_SESSION &&
      endLeaveCode === text.SECOND_SESSION) ||
    (startLeaveCode === endLeaveCode && startLeaveCode === text.WHOLE_DAY)
  ) {
    return 1;
  } else {
    return 0.5;
  }
};

const calcTwoDaysLeaves = (startLeaveCode: string, endLeaveCode: string) => {
  if (
    (startLeaveCode === text.FIRST_SESSION &&
      endLeaveCode === text.SECOND_SESSION) ||
    (startLeaveCode === text.WHOLE_DAY && endLeaveCode === text.WHOLE_DAY)
  ) {
    return 2;
  } else {
    if (
      (startLeaveCode === text.WHOLE_DAY &&
        endLeaveCode === text.FIRST_SESSION) ||
      (startLeaveCode === text.SECOND_SESSION &&
        endLeaveCode === text.WHOLE_DAY)||(startLeaveCode === endLeaveCode)
    ) {
      return 1.5;
    } else {
      return 1;
    }
  }
};

const calcLeaveDays = (
  startDate: Moment,
  startLeaveCode: string,
  endDate: Moment,
  endLeaveCode: string,
) => {
  let count = 0;
  if (!moment(endDate).diff(startDate)) {
    count = calculateSession(startLeaveCode, endLeaveCode);
  } else {
    const leaveDays = moment(endDate).diff(moment(startDate), "days");
    if (leaveDays === 1) {
      count = calcTwoDaysLeaves(startLeaveCode, endLeaveCode);
    } else {
      const diff = calcTwoDaysLeaves(startLeaveCode, endLeaveCode);
      count = leaveDays - 1 + diff;
    }
  }

  return count;
};
export function column(
  actionTaken: (value: string, data: TableData) => void,
  downloadFile: (file:string)=> void
) {
  const fixed = window.innerWidth > 600 ? "right" : "";

  return [
    {
      title: text.ASSOCIATE_NAME,
      key: "associateName",
      dataIndex: "associateName",
      render: (associateName: string) => {
        return <Row type="flex" justify="start">{associateName}</Row>;
      },
    },
    {
      title: text.LEAVE_TYPE,
      key: "leaveType",
      dataIndex: "leaveType",
      render: (leaveType: string) => {
        return <Row type="flex" justify="start">{leaveType}</Row>;
      },
    },
    {
      title: text.FILE_ATTACHMENT,
      key: "file",
      dataIndex: "file",
      render: (file: string,row:TableData) => {
        if(!file){
        return <Row type="flex" justify="start">{text.NOT_AVAILABLE}</Row>
        }
        return <Row type="flex" justify="start"><a className="c-info bg-white" style={{textDecoration:"underline"}} onClick={()=> downloadFile(file)}>{text.DOWNLOAD}</a></Row>;
      },
    },
    {
      title: text.FROM_DATE,
      key: "fromDate",
      dataIndex: "fromDate",
      align:"center",
      render: (fromDate: string) => {
        return <Row type="flex" justify="start">{fromDate}</Row>;
      },
    },
    {
      title: text.TO_DATE,
      key: "toDate",
      dataIndex: "toDate",
      align:"center",
      render: (toDate: string) => {
        return <Row type="flex" justify="start">{toDate}</Row>;
      },
    },
    {
      title: text.NO_OF_DAYS,
      key: "noOfDays",
      dataIndex: "noOfDays",
      align:"center",
      render: (noOfDays: string) => {
        return <Row type="flex" justify="center">{noOfDays}</Row>;
      },
    },
    {
      title: text.REMARKS,
      key: "exception",
      dataIndex: "exception",
      align:"center",
      render: (exception: string) => {
        if (exception !== null) {
          return (
            <Tooltip placement="topLeft" title={exception}>
              <Icon type="exclamation-circle" className="pl-3 c-secondary" />
            </Tooltip>
          );
        } else {
          return <Icon type="minus" className="pl-3 c-secondary" />;
        }
      },
    },
    {
      title: text.STATUS,
      key: "status",
      dataIndex: "status",
      align:"center",
      render: (status: string, row: any) => {
        let colour = "#00b6ef";
        if (row.statusCode === "PAUT" || row.statusCode === "PATC") {
          colour = "#fbb517";
        } else if (row.statusCode === "AUTH") {
          colour = "#02af9b";
        } else if (row.statusCode === "REJE") {
          colour = "#ee2e22";
        } else if (row.statusCode === "CAN") {
          colour = "rgb(0, 182, 239)";
        }
        return <Text style={{ color: colour }}>{status}</Text>;
      },
    },
    {
      title: text.COMMENTS,
      key: "additionalReason",
      dataIndex: "additionalReason",
      render: (additionalReason: string) => {
        return <Row type="flex" justify="start" >{additionalReason}</Row>;
      },
    },
    {
      title: text.ACTION,
      key: "action",
      dataIndex: "action",
      align:"center",
      fixed,
      render: (action: string, row: any, index: number) => {
        if (row.status === text.PENDING) {
          action = text.SELECT;
        } else if (row.status === text.AUTHORIZED) {
          action = text.AUTHORIZED;
        } else if (row.status === text.REJECTED) {
          action = text.REJECTED;
        } else if (row.status) {
          action = row.status;
        } else {
          action = text.SELECT;
        }
        return (
          <Row type="flex" justify="start">
          <DropDown
            actionAlreadyTaken={isActionAlreadyTaken(row.statusCode)}
            menuList={getActions(row.status)}
            getSelectedValue={(args)=>actionTaken(args,row)}
            dropdownText={action}
            index={index}
          />
          </Row>
        );
      },
    },
  ];
}

export function summary(data: SummaryDetails[]) {
  if (data.length !== 0) {
    overviewData[0].value = data.filter(
      element => element.statusCode === text.ALL,
    )[0].recordCount;
    overviewData[1].value = data.filter(
      element => element.statusCode === text.PENDING_CODE,
    )[0].recordCount;
    overviewData[2].value = data.filter(
      element => element.statusCode === text.ACTION_TAKEN_CODE,
    )[0].recordCount;
  }
  return overviewData;
}

export const overviewData: any = [
  {
    title: text.TOTAL_REQUESTS,
    value: 0,
  },
  {
    title: text.ACTION_PENDING,
    value: 0,
  },
  {
    title: text.ACTION_TAKEN,
    value: 0,
  },
];
export const colors: string[] = ["#00b6ef", "#fbb517", "#02af9b"];
