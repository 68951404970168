import React from "react";
import LeaveCards from "components/LeavesCards";
import TimesheetCards from "components/TimesheetCards";
import ReimbursementCards from "components/ReimbursementCards";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";

export default function Dashboard(props: any) {
  const { contextStore } = useSelector((state: ApplicationState) => state);
  return (
    <div className="p-2" id="section">
      {contextStore.allowedActivities[ModuleType.LEAVE] && <LeaveCards />}
      {contextStore.allowedActivities[ModuleType.ATTENDANCE] && (
        <TimesheetCards />
      )}
      {contextStore.allowedActivities[ModuleType.REIMBURSEMENT] && (
        <ReimbursementCards />
      )}
    </div>
  );
}
