import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import AssociateList from "../../components/AssociateList/AssociateList";
import { Reportees } from "../../models/associate.data";
import { AssociateActions } from "screens/associate/actions";
import Loader from "components/Loader";
import AssociateHeader from "../../components/AssociateHeader/AssociateHeader";

export default function Associate() {
  const dispatch = useDispatch();

  const [gridView, setGridView] = useState(true);
  const [searchText, setSearchText] = useState("");

  const reporteeList: Reportees[] = useSelector((state: any) => {
    return state.associateStore.repoteesListDetails;
  }, shallowEqual);

  const isLoading: boolean = useSelector((state: any) => {
    return state.associateStore.loading;
  }, shallowEqual);

  const handleGridView = () => {
    setGridView(!gridView);
  };

  const getReporteeList = () => {
    if (!searchText) return reporteeList;
    return reporteeList.filter(item => {
      return item.employeeName.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  useEffect(() => {
    dispatch(AssociateActions.getReporteesListList.started());
  }, []);

  if (isLoading)
    return (
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="min-vh-content">
        <Loader />
      </Row>
    );
  return (
    <Row className="p-6" id="associate">
      <AssociateHeader
        gridView={gridView}
        setSearchText={setSearchText}
        handleGridView={handleGridView}
      />
      <AssociateList gridView={gridView} associateList={getReporteeList()} />
    </Row>
  );
}
