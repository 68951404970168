import { AuthHelper } from "./../helpers/AuthHelper";
import {
  HttpContentType,
  DynamicQueryPath,
  EndPoints,
} from "../store/model/ApiConfig.data";
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { getCurrentBaseUrl } from "../AppSettings.data";
import { Logging } from "../helpers/Logging";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { store } from "store/Store";

export default class ApiService {
  protected readonly endPoint: EndPoints;

  public constructor(endPoint: EndPoints) {
    this.endPoint = endPoint;
  }

  private getBaseUrl() {
    if (this.isAuthApi()) {
      return process.env.REACT_APP_AZURE_BASE_URL;
    }
    return getCurrentBaseUrl();
  }

  public get<T = void>(path: DynamicQueryPath): Promise<T> {
    Logging.debug(`ApiService.get ${this.getUrl(path)}`, { path });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .get(this.getUrl(path), this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.debug(`ApiService.get ${this.getUrl(path)} @Response`, {
              result: res,
            });
            resolve(res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.get ${this.getUrl(path)} @Error`, {
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  public post<T = void>(
    path: DynamicQueryPath,
    body: object | string | number | boolean,
    contentType: HttpContentType = HttpContentType.Json
  ): Promise<T> {
    Logging.debug(`ApiService.post ${this.getUrl(path)}`, {
      path,
      body,
    });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance.post(this.getUrl(path), body, this.getConfig(contentType)).then(
        (res: any) => {
          Logging.debug(`ApiService.post ${this.getUrl(path)} @Response`, {
            result: res,
          });
          resolve(res.data);
        },
        (err: any) => {
          Logging.error(`ApiService.post ${this.getUrl(path)} @Error`, {
            error: err,
          });
          reject(this.processError(err));
        }
      )
    );
  }

  public put<T = void>(
    path: DynamicQueryPath,
    body?: object | string | number | boolean,
    contentType: HttpContentType = HttpContentType.Json
  ): Promise<T> {
    Logging.debug(`ApiService.put ${this.getUrl(path)}`, {
      path,
      body,
    });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance.put(this.getUrl(path), body, this.getConfig(contentType)).then(
        (res: any) => {
          Logging.debug(`ApiService.put ${this.getUrl(path)} @Response`, {
            result: res,
          });
          resolve(res.data);
        },
        (err: any) => {
          Logging.error(`ApiService.put ${this.getUrl(path)} @Error`, {
            error: err,
          });
          reject(this.processError(err));
        }
      )
    );
  }

  public delete<T = void>(path: DynamicQueryPath): Promise<T> {
    Logging.debug(`ApiService.delete ${this.getUrl(path)}`, { path });
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .delete(this.getUrl(path), this.getConfig(HttpContentType.Json))
        .then(
          (res: any) => {
            Logging.debug(`ApiService.delete ${this.getUrl(path)} @Response`, {
              result: res,
            });
            resolve(res.data);
          },
          (err: any) => {
            Logging.error(`ApiService.delete ${this.getUrl(path)} @Error`, {
              error: err,
            });
            reject(this.processError(err));
          }
        )
    );
  }

  /**
   * Define all the error codes based on definition in backend
   * Throws 500 if the error code is null or empty
   */
  private processError(error: any): { errorCode: number; errorMsg: Error } {
    console.log(error, error.response);
    let errorCode = 500;
    let errorMsg = error;
    const apiError = error.response;

    if (error && error.response && error.response.status) {
      errorCode = error.response.status;
    }
    if (error && error.response && error.response.statusText) {
      errorMsg = new Error(error.response.statusText);
    }
    const errorResponse = { errorCode, errorMsg, apiError };

    switch (errorCode) {
      case 400:
        if (apiError) {
          ModalPopUp({
            type: "error",
            title: "Oops! Something Went Wrong",
            description:
              (apiError.data[0] && apiError.data[0].description) ||
              (apiError.data && apiError.data.description),
          });
        }
        errorResponse.errorMsg = new Error("Bad Request");
        break;
      case 401:
        errorResponse.errorMsg = new Error("Unauthorised");
        break;
      case 404:
        errorResponse.errorMsg = new Error("Resource not found");
        break;
      case 500:
        errorResponse.errorMsg = new Error("Internal server error");
        break;
      default:
        errorResponse.errorMsg = new Error("Internal server error");
    }
    return errorResponse;
  }

  private isContextApi(path: string): boolean {
    return path.includes("user-config-ramco-india/me/contexts");
  }

  private isAuthApi() {
    return this.endPoint.includes(EndPoints.fetchToken);
  }
  /**
   * Axios request object
   */
  private getConfig(contentType: HttpContentType): AxiosRequestConfig {
    const contextData = store.getState().contextStore.contextDetails;
    let headers: any = !this.isAuthApi()
      ? {
          "Content-Type": contentType.toString(),
          Authorization: AuthHelper.getAccessToken(),
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
        }
      : {
          "Content-Type": contentType.toString(),
        };
    if (!this.isContextApi(this.endPoint) && !this.isAuthApi()) {
      headers = {
        ...headers,
        "context-lang-id": contextData.data.userDefaults.langId,
        "context-ou-id": contextData.data.userDefaults.ouId,
        "context-role-name": contextData.data.userDefaults.roleName,
      };
    }
    return {
      headers: { ...headers, Pragma: "no-cache", "Cache-Control": "no-cache" },
    };
  }

  /**
   * Generates axios instance and adds an interceptor to monitor api errors
   */
  private getAxiosInstance() {
    const instance = axios.create();
    instance.interceptors.response.use(
      this.responseSuccessHandler,
      this.responseErrorHandler
    );
    return instance;
  }

  private responseSuccessHandler = (data: AxiosResponse<any>) => data;

  private responseErrorHandler = (error: AxiosError) => {
    if (
      (error.config && error.response && error.response.status === 403) ||
      (error.config && error.response && error.response.status === 401)
    ) {
      return AuthHelper.refreshAccessToken().then(
        ({ access_token }: { access_token: string }) => {
          error.config.headers.Authorization = access_token;
          return axios.request(error.config);
        }
      );
    } else {
      throw error;
    }
  };

  /**
   * Generates complete API url based on BaseUrl, Static EndPoint and
   * dynamic params provided.
   */
  private getUrl(path: DynamicQueryPath): string {
    let url: string = `${this.getBaseUrl()}/${this.endPoint}`;
    if (path) {
      if (path.dynamicRoute && path.dynamicRoute.length > 0) {
        path.dynamicRoute.forEach((route: string) => {
          url += `/${route}`;
        });
      }
      if (path.dynamicQueryParams) {
        let separator = "?";
        path.dynamicQueryParams.forEach(query => {
          for (const key in query) {
            if (query[key]) {
              url += `${separator}${encodeURI(key)}=${encodeURI(
                query[key]!.toString()
              )}`;
              separator = "&";
            }
          }
        });
        // for (const key in path.dynamicQueryParams) {
        //   if (path.dynamicQueryParams[key]) {
        //     url += `${separator}${encodeURI(key)}=${encodeURI(
        //       path.dynamicQueryParams[key]!.toString()
        //     )}`;
        //     separator = "&";
        //   }
        // }
      }
    }
    // if (!url.endsWith("/")) {
    //   url = url + "/"
    // }
    return url;
  }
}
