import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  LeaveSummary,
  TimesheetSummary,
  ReimbursementSummary,
} from "models/summaryApis.data";
import { getRequestData } from "models/timesheet.data";

export class DashboardService {
  public getLeavesSummary(
    requestData: getRequestData,
  ): Promise<LeaveSummary> | LeaveSummary {
    const service: ApiService = new ApiService(EndPoints.leaveSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
  public getTimesheetSummary(
    requestData: getRequestData,
  ): Promise<TimesheetSummary> | TimesheetSummary {
    const service: ApiService = new ApiService(EndPoints.timesheetSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
  public getReimbursementSummary(
    requestData: getRequestData,
  ): Promise<ReimbursementSummary> | ReimbursementSummary {
    const service: ApiService = new ApiService(EndPoints.reimbursementSummary);
    return service.get({
      dynamicQueryParams: [
        { internalRole: requestData.internalRole },
        { toDateSearch: requestData.endDate },
        { fromDateSearch: requestData.startDate },
      ],
    });
  }
}

export default new DashboardService();
