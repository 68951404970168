import React, { useState, useEffect } from "react";
import { ICustomImageProps, ImageViewType } from "./type";
import { AuthHelper } from "helpers";
import { transferImage } from "AssetHelper";
import { EndPoints } from "store/model/ApiConfig.data";
import axios from "axios";

export default function CustomImage(props: ICustomImageProps) {
  const { file, relativePath, type, width } = props;
  const [showPropImage, setShowPropImage] = useState<boolean>(false);
  const [showImage, setShowImage] = useState(transferImage);

  useEffect(() => {
    getImageUri();
  }, []);
  const getImageUri = () => {
    const headerValues = {
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      Authorization: AuthHelper.getAccessToken(),
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    };
    if (!AuthHelper.getAccessToken()) {
      return;
    }
    const uri = `${process.env.REACT_APP_BASE_URL}/${EndPoints.CORE_UTILITY_DOWNLOAD_FILE}?relativepath=${relativePath}&fileId=${file}`;
    if (file === null) {
      return;
    }
    axios
      .get(uri, { headers: headerValues, responseType: "blob" })
      .then(res => {
        const blob = new Blob([res.data], {
          type: "image/png",
        });
        const objectURL = URL.createObjectURL(blob);
        setShowImage(objectURL);
      })
      .catch(err => {
        console.log(err);
      })
      .finally();
  };

  const onError = () => setShowPropImage(false);

  return (
    <img
      src={showImage}
      alt="img"
      onError={onError}
      style={
        type === ImageViewType.ROUND
          ? { borderRadius: "50px", border: "1px solid white", width }
          : { width }
      }
    />
  );
}
