export const size = {
  h1: 32,
  h2: 22,
  h3: 20,
  large: 19,
  regular: 17,
  medium: 16,
  small: 15,
  xsmall: 13,
  xxsmall: 11,
};

export const style = {
  h1: {
    fontSize: size.h1,
  },
  h2: {
    fontSize: size.h2,
  },
  h3: {
    fontSize: size.h3,
  },
  normal: {
    fontSize: size.regular,
  },
  large: {
    fontSize: size.large,
  },
  medium: {
    fontSize: size.medium,
  },
  small: {
    fontSize: size.small,
  },
  xsmall: {
    fontSize: size.xsmall,
  },
  xxsmall: {
    fontSize: size.xxsmall,
  },
};
