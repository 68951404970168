import React, { useEffect } from "react";
import { Col, Row, Icon } from "antd";
import { associateImage } from "AssetHelper";
import { useSelector, shallowEqual } from "react-redux";
import Search from "components/Search";
import { Reportees } from "models/associate.data";
import { style } from "styles/Fonts";

export default function AssociateHeader(props: {
  handleGridView: () => void;
  gridView: boolean;
  setSearchText: (value: string) => void;
}) {
  const { handleGridView, gridView, setSearchText } = props;
  const reporteeList: Array<Reportees> = useSelector((state: any) => {
    return state.associateStore.repoteesListDetails.data;
  }, shallowEqual);

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      className="p-0"
      id="associate">
      <Col md={{ span: 8 }} className="pb-2 pt-0" id="heading">
        <Row type="flex" align="middle" className="align-items-center">
          <Col>
            <div>
              <img src={associateImage} className="h-3" alt="associate Icon" />
              &nbsp;<span style={style.large}> Associates</span>
            </div>
          </Col>

          <Col></Col>
          <Col></Col>
        </Row>
      </Col>
      <Col className="pb-2" id="heading">
        <Row
          justify="space-between"
          type="flex"
          align="middle"
          className="align-items-centre">
          <Col className="ml-0 p-0">
            <Search
              placeholder={"Search Name"}
              data={[]}
              getSearchedValue={setSearchText}
            />
          </Col>
          <Col>
            {gridView && (
              <a
                onClick={handleGridView}
                className="ml-2 pt-2 p-1 bg-white"
                style={{ outline: "none" }}>
                <Icon type="unordered-list" style={{ fontSize: "18px" }} />
              </a>
            )}
            {!gridView && (
              <a
                onClick={handleGridView}
                className="ml-2 pt-2 p-1 bg-white"
                style={{ outline: "none" }}>
                <Icon type="appstore" style={{ fontSize: "18px" }} />
              </a>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
