import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { UserContextData } from "../../../../models/userGontext.data";

export class ContextService {
  public fetchAccessRefreshtoken(code: string): any {
    const service: ApiService = new ApiService(EndPoints.fetchToken);
    return service.get({
      dynamicQueryParams: [
        {
          code,
          type: "WEB",
          redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL || "",
        },
      ],
    });
  }

  public getContextDetails(): Promise<UserContextData> | UserContextData {
    const service: ApiService = new ApiService(EndPoints.context);
    return service.get({});
  }

  public fetchRelativePathData() {
    const service: ApiService = new ApiService(
      EndPoints.RELATIVE_PATH_EMPLOYEE
    );
    return service.get({});
  }
  
  public fetchUserActivities() {
    const service: ApiService = new ApiService(EndPoints.USER_ACTIVITIES);
    return service.get({});
  }
}

const contextService = new ContextService();
export default contextService;
