import call from "./assets/Images/call.svg";
import phone from "./assets/Images/phone.svg";
import home from "./assets/Images/home.svg";
import leave from "./assets/Images/leave.svg";
import timesheet from "./assets/Images/timesheet.svg";
import reimbursement from "./assets/Images/reimbursement.svg";
import associate from "./assets/Images/associates.svg";
import raiseTicket from "./assets/Images/raiseATicket.svg";
import mandates from "./assets/Images/mandates.svg";
import transfer from "./assets/Images/transfer.svg";
import mail from "./assets/Images/mail.svg";
import invoice from "./assets/Images/invoice.svg";
import actionTaken from "./assets/Images/action_taken.svg";
import pending from "./assets/Images/pending.svg";
import reject from "./assets/Images/reject.svg";
import exception from "./assets/Images/exception.svg";
import gridView from "./assets/Images/grid_view.svg";
import filter from "./assets/Images/filter.svg";
import dashboardTotal from "./assets/Images/dashboard_total.svg";
import dashboardTotalActions from "./assets/Images/dashboard_total_actions.svg";
import dashboardException from "./assets/Images/dashboard_exception.svg";
import dashboardPending from "./assets/Images/dashboard_pending.svg";
import dashboardApprove from "./assets/Images/dashboard_approve.svg";
import dashboardTaken from "./assets/Images/dashboard_taken.svg";
import dashboardReject from "./assets/Images/dashboardReject.svg";
import homePageBg from "./assets/Images/homePageBg.png";
import adeccoLogoImage from "./assets/Images/adeccoLogo.svg";
import homePageFullScreenBG from "./assets/Images/homePageFullScreenBG.png";
import whatsapp from "./assets/Images/icons8-whatsapp (2).svg";

export const callImage = call;
export const phoneImage = phone;
export const homeImage = home;
export const leaveImage = leave;
export const timesheetImage = timesheet;
export const reimbursementImage = reimbursement;
export const associateImage = associate;
export const raiseTicketImage = raiseTicket;
export const mandatesImage = mandates;
export const transferImage = transfer;
export const mailImage = mail;
export const invoceImage = invoice;
export const actionTakenImage = actionTaken;
export const pendingImage = pending;
export const rejectImage = reject;
export const exceptionImage = exception;
export const gridViewImage = gridView;
export const filterImage = filter;
export const dashboardTotalImage = dashboardTotal;
export const dashboardTotalActionsImage = dashboardTotalActions;
export const dashboardExceptionImage = dashboardException;
export const dashboardPendingImage = dashboardPending;
export const dashboardApproveImage = dashboardApprove;
export const dashboardTakenImage = dashboardTaken;
export const dashboardRejectImage = dashboardReject;
export const homeBackground = homePageBg;
export const adeccoLogo = adeccoLogoImage;
export const homeScreenImage=homePageFullScreenBG;
export const whatsappImage = whatsapp;
