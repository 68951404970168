import { AuthHelper } from "helpers";
import { EndPoints } from "store/model/ApiConfig.data";
import axios from "axios";

export const getPDFURI = async(file:string,relativepath:string) => {
    let error:number=200;
    if(file === null){
      return "";
    }

    let fileName="";
    if(file.split(".").length===1){
      if(file.split(" ").length>1){
        fileName=`${((file.split(" ")).join("_"))}.pdf`;
      }else {
        fileName=`${file}.pdf`
      }
    }else {
      fileName=file;
    }
    
    const headerValues = {
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      Authorization: AuthHelper.getAccessToken(),
      Pragma: "no-cache", "Cache-Control": "no-cache"
    };

    const uri = `${process.env.REACT_APP_BASE_URL}/${EndPoints.CORE_UTILITY_DOWNLOAD_FILE}?relativepath=${relativepath}&fileId=${file}`;

   await axios
      .get(uri, { headers: headerValues,responseType: "blob"})
      .then(res => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([res.data]), fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } 
      })
      .catch((err) => {
        error=err.response.status;
      })
      .finally(() =>{}
      );
      return error;
  };
