import React, { useEffect, useState } from "react";
// import phone from "../../assets/Images/phone.png";
import {
  phoneImage,
  homeImage,
  leaveImage,
  timesheetImage,
  reimbursementImage,
  associateImage,
  raiseTicketImage,
  mandatesImage,
  transferImage,
  mailImage,
  invoceImage,
  adeccoLogo,
  whatsappImage,
} from "../../AssetHelper";
import {
  Layout,
  Menu,
  Row,
  Button,
  Icon,
  Input,
  Divider,
  Select,
  Badge,
  Popover,
  Tooltip,
  Modal,
  Drawer,
} from "antd";
import { Link, useLocation, Switch, Route, Redirect } from "react-router-dom";
import "./Layout.less";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  isAuthFailed,
  isAuthSuccess,
  userProjects,
} from "store/auth/AuthReducer";
import { AuthActions } from "store/auth/AuthActions";
import { useHistory } from "react-router-dom";
import Leave from "screens/leave/Leave";
import Dashboard from "screens/dashboard/Dashboard";
import Profile from "screens/profile/container/DetailsContainer";
import Reimbursement from "screens/reimbursement/Reimbursement";
import Timesheet from "screens/timesheet/Timesheet";
import { AuthHelper, AuthConstants } from "helpers";
import { ContextType } from "models/userGontext.data";
import Associate from "../../screens/associate/Associate";
import CustomImage from "components/CustomImage/CustomImage";
import { ImageViewType } from "components/CustomImage/type";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";
import text from "text";
import { getRelativePathForAssociate } from "common/Utils.tsx/GetRelativePath";

const { Header, Sider, Content } = Layout;

export default function AppLayout() {
  const { contextStore } = useSelector((state: ApplicationState) => state);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const [isOpenPopOver, setIsOpenPopOver] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authSuccess: boolean = useSelector(isAuthSuccess);
  const [sidebarOverlay, setSidebarOverlay] = useState(true);
  const [mobileSidebar, setMobileSidebar] = useState<boolean>();

  const menuItemKeys = [
    "timesheet",
    "leave",
    "reimbursement",
    "associate",
    "dashboard",
  ];

  const checkUserValidity = () => {
    dispatch(AuthActions.checkUserValidity.started(undefined));
  }

  useEffect(() => {
    setContentHeight(window.innerHeight - 90);
    checkUserValidity();
    if (window.innerWidth < 600) {
      setMobileSidebar(true);
      setSidebarOverlay(false);
    } else {
      setMobileSidebar(false);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 600) {
        setMobileSidebar(true);
        setSidebarOverlay(false);
      } else {
        setMobileSidebar(false);
      }
    });
    let selectedMenu;
    menuItemKeys.forEach(element => {
      if (location.pathname.includes(element)) {
        selectedMenu = element;
      }
    });
    setSelectedMenu(selectedMenu);
  }, []);


  const onLogout = async () => {
    setIsOpenPopOver(false);
    Modal.confirm({
      title: "Are you sure, you wanted to Logout?",
      content: "You will be redirected to Log in page.",
      onOk: async () => {
        await AuthHelper.onLogoutUser();
        history.replace("/");
      },
    });
  };

  const userMenu = (
    <div className="m-1">
      <div className="my-2 mx-3">
        <b>{contextStore.contextDetails.data?.fullName}</b>
      </div>
      <Divider style={{ margin: "4px 0" }} />
      {contextStore.userDetails.data?.find(
        (item: any) => item.internalRole === "ASC",
      ) && (
        <Link to="/home/profile" className="p-0">
          <div className="d-flex my-3">
            <div className="mr-2">
              <Icon type="snippets"></Icon>
            </div>
            <div>
              <div>My Profile</div>
              <div>
                <small className="c-secondary">
                  {contextStore.contextDetails.data?.fullName}
                </small>
              </div>
            </div>
          </div>
        </Link>
      )}
      <div
        className="d-flex"
        style={{ cursor: "pointer" }}
        key="logout"
        onClick={onLogout}>
        <div className="mr-2">
          <Icon type="logout"></Icon>
        </div>
        <div>Logout</div>
      </div>
    </div>
  );


  const [selectedMenu, setSelectedMenu] = useState();

  const openMenu = () => {
    if (window.innerWidth < 750) {
      setSidebarOverlay(true);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const getMenuItemClass = (type: string) =>
    contextStore.allowedActivities[type] ? "c-black" : "";

  const isAssociateDisabled = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === "CLI"
  )
    ? false
    : true;

  const renderMenu = () => {
    return (
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={selectedMenu}
        selectedKeys={selectedMenu}
        className="mt-3">
        <Menu.Item key="dashboard">
          <Link to="/home/dashboard" onClick={() => setSidebarOverlay(false)}>
            <img src={homeImage} className="pr-7 h-5" />
            <span className="c-black">Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="leave"
          disabled={!contextStore.allowedActivities[ModuleType.LEAVE]}>
          <Link to="/home/leave" onClick={() => setSidebarOverlay(false)}>
            <img src={leaveImage} className="pr-7 h-5" />
            <span className={getMenuItemClass(ModuleType.LEAVE)}>Leave</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="timesheet"
          disabled={!contextStore.allowedActivities[ModuleType.ATTENDANCE]}>
          <Link to="/home/timesheet" onClick={() => setSidebarOverlay(false)}>
            <img src={timesheetImage} className="pr-7 h-5" />
            <span className={getMenuItemClass(ModuleType.ATTENDANCE)}>
              Timesheet
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="reimbursement"
          disabled={!contextStore.allowedActivities[ModuleType.REIMBURSEMENT]}>
          <Link
            to="/home/reimbursement"
            onClick={() => setSidebarOverlay(false)}>
            <img src={reimbursementImage} className="pr-7 h-5" />
            <span className={getMenuItemClass(ModuleType.REIMBURSEMENT)}>
              Reimbursement
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="associate" disabled={isAssociateDisabled}>
          <Link to="/home/associate" onClick={() => setSidebarOverlay(false)}>
            <img src={associateImage} className="pr-6 h-5" />
            <span className={isAssociateDisabled ? "" : "c-black"}>
              Associates
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7" disabled>
          <Tooltip placement="right" title={"Coming Soon"}>
            <img src={mandatesImage} className="pr-9 h-5" />
            <span>Mandates</span>
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="8" disabled>
          <Tooltip placement="right" title={"Coming Soon"}>
            <img src={transferImage} className="pr-8 h-5" />
            <span>Transfer</span>
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="9" disabled>
          <Tooltip placement="right" title={"Coming Soon"}>
            {<img src={invoceImage} className="pr-9 h-5" />}
            <span>Invoice</span>
          </Tooltip>
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    let selectedMenuVar;
    menuItemKeys.forEach(element => {
      if (location.pathname.includes(element)) {
        selectedMenuVar = element;
      }
    });
    setSelectedMenu(selectedMenuVar);
  }, [location, menuItemKeys]);

  const onclose = () => {
    setSidebarOverlay(false);
  };
  if (!AuthHelper.getItem(AuthConstants.ACCESS_TOKEN)) {
    history.replace("/");
    return <></>;
  }
  return (
    <Layout className="min-vh-100">
      <Row className="c-white p-1 bg-black">
        For Support:&emsp;{<img src={whatsappImage} className="h-4" />}&nbsp;
        <a href="tel:7625003333"></a>
        7625003333&emsp;
        {<img src={mailImage} className="h-4" />}&nbsp;
        <a href="mailto:DSD.india@adecco.com" target="_blank" className="c-white">
        DSD.india@adecco.com
        </a>
      </Row>
      <Header className="bg-primary c-white">
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ height: "55px" }}>
          <Row type="flex" align="middle">
            <Button
              type="link"
              icon="menu"
              className="px-3 c-white hamburger-icon"
              size="large"
              onClick={() => {
                openMenu();
              }}></Button>
            <Link to="/home/dashboard">
              <h3 className="c-white p-0 m-0 ml-3 ff-secondary">
                <img src={adeccoLogo} className="h-6" />
              </h3>
            </Link>
          </Row>
          <Row type="flex" align="middle">
            {authSuccess ? (
              <React.Fragment>
                <Popover
                  content={userMenu}
                  visible={isOpenPopOver}
                  onVisibleChange={setIsOpenPopOver}
                  trigger="hover"
                  className="mr-4"
                  placement="bottomRight">
                  <Button
                    type="primary"
                    className="c-white w-60"
                    shape="circle"
                    style={{ height: "40px" }}
                  >{ contextStore && contextStore.userDetails && contextStore.userDetails.data[0] &&
                    <CustomImage
                      file={contextStore.userDetails.data[0].profilePhoto}
                      relativePath=
                        {getRelativePathForAssociate(contextStore.relativePath.data,text.CLIENT_RELATIVEPATH_KEY)}
                      type={ImageViewType.ROUND}
                      width="45px"
                    />}
                  </Button>
                </Popover>
              </React.Fragment>
            ) : (
              <Button type="ghost" shape="circle" className="mr-3 c-white">
                <Link to="/login">
                  <Icon type="user" />
                </Link>
              </Button>
            )}
          </Row>
        </Row>
      </Header>
      <Layout>
        {mobileSidebar ? (
          <Drawer
            placement={"left"}
            closable={false}
            onClose={() => onclose()}
            visible={sidebarOverlay}>
            {renderMenu()}
          </Drawer>
        ) : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className="min-vh-content bg-white">
            {renderMenu()}
          </Sider>
        )}
        <Content style={{ maxHeight: contentHeight ? contentHeight : "85vh" }}>
          <Switch>
            <Route path="/home/leave" component={Leave}></Route>
            <Route path="/home/timesheet" component={Timesheet}></Route>
            <Route path="/home/reimbursement" component={Reimbursement}></Route>
            <Route path="/home/associate">
              <Associate />
            </Route>
            <Route path="/home/profile">
              <Profile />
            </Route>
            <Route path="/home/dashboard">
              <Dashboard />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
