/**
 * Defines HTTP content type supported by APIs.
 */
export enum HttpContentType {
  Json = "application/json",
  File = "multipart/form-data",
}

/**
 * Static end-points for all APIs. In case endpoint has dynamic values,
 * pass it in query object while calling API-SERVICE and it will be
 * appended at the end.
 */
export enum EndPoints {
  Auth = "login",
  EndSession = "connect/endsession",
  ErModelJsonData = "api/get-ermodel",
  ErData = "api/ermodel",
  ProjectMetaDataApi = "api/project-details",
  leave = "leave/approver/list",
  approveleave = "leave/approver",
  timesheet = "timesheet/attendance",
  repotees = "employee/Reportees",
  profileUser = "employee/userprofile",
  profileContacts = "employeeprofile/contact/informationlist",
  profileQualifications = "employeeprofile/qualification/list",
  profileWorkExperience = "employeeprofile/workexperience/list",
  profileFamilyMembers = "employeeprofile/family/informationlist",
  profilePersonalInformation = "employeeprofile/personalinformation",
  leaveSummary = "leave/summarydisplay",
  timesheetSummary = "timesheet/attendance/summarydisplay",
  reimbursementSummary = "expense/summarydisplay",
  approveTimesheet = "timesheet/approver",
  reimbursement = "expense/approver",
  approveExpense = "expense/approver",
  context = "user-config-ramco-india/me/contexts",
  fetchToken = "FetchAccessToken",
  CORE_UTILITY_DOWNLOAD_FILE = "core-utilityservices/file",
  RELATIVE_PATH_EMPLOYEE = "employee/document/relativepath",
  USER_ACTIVITIES = "user-config-ramco-india/me/activities",
  ITEM_DETAILS="expense/detailsbyidentifier",
}

interface Query {
  [paramName: string]: string | number | boolean | null;
}

/**
 * Adds values dynamically to Api Endpoints
 * Use @param DynamicRoute to append urls like "baseUrl/staticEndpoint/{routeParam1}/{routeParam2}/..."
 * Use @param dynamicQueryParams to append urls like "baseUrl/staticEndpoint?{name1}={value1}&{name2}={value2}..." *
 */
export interface DynamicQueryPath {
  readonly dynamicRoute?: string[] | undefined | null;
  readonly dynamicQueryParams?: Query[] | undefined | null;
}
