export default {
  APPROVE: "Approve",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  PENDING: "Pending",
  REIMBURSEMENT: "Reimbursement",
  TIMESHEET: "Timesheet",
  ATTENDANCE: "Attendance",
  LEAVE: "Leave",
  AUTHORIZE: "Authorize",
  REJECT: "Reject",
  SELECT: "Select",
  AUTHORIZED: "Authorized",
  ALL: "All",
  ARE_YOU_SURE: "Are you sure?",
  TIMESHEETS: `Timesheets`,
  REMBUIRSEMENTS: `Rembuirsements`,
  LEAVES: "Leaves",
  REJECTED_MESSAGE: `You have Rejected`,
  APPROVED_MESSAGE: `You have Approved`,
  REJECTED_REIMBURSEMENT: `You have Rejected Reimbursement`,
  APPROVED_REIMBURSEMENT: `You have Approved Reimbursement`,
  ACTION_TAKEN: "Action Taken",
  ASSOCIATE_NAME: "Associate Name",
  LEAVE_TYPE: "Leave Type",
  FROM_DATE: "From Date",
  TO_DATE: " To Date",
  NO_OF_DAYS: "No of days",
  EXECPTION: "Exception",
  REMARKS: "Remarks",
  STATUS: "Status",
  ACTION: "Action",
  TOTAL_REQUESTS: "Total Requests",
  ACTION_PENDING: "Action Pending",
  FIRST_SESSION: "MSES",
  SECOND_SESSION: "NSES",
  WHOLE_DAY: "WDAY",
  APR: "APR",
  IN_TIME: "In Time",
  OUT_TIME: "Out Time",
  TOTAL_HOURS_CLOCKED: "Total Hrs Clocked",
  BREAK_IN_MINS: "Break (in Min)",
  REJECT_TIMESHEET: "rej_tmsht",
  APPROVE_TIMESHEET: "aprv_tmsht",
  GET_TIMESHEET_ACTION: "get_aprv_res_dtls",
  EXPENSE_ID: "Expense Id",
  EXPENSE_DESCRIPTION: "Expense Description",
  DATE: "Date",
  REQUESTED_AMOUNT: "Requested Amount",
  AUTHORIZED_AMOUNT: "Authorized Amount",
  PENDING_CODE: "PEND",
  ACTION_TAKEN_CODE: "ACTD",
  RUPEE_SYMBOL: "\u20B9",
  FIRST_SESSION_DESC: "First Session",
  SECOND_SESSION_DESC: "Second Session",
  WHOLE_DAY_DESC: "Whole Day",
  UNAUTHORIZED_VIEW: "You are not authorized to View this page...",
  FILE_ATTACHMENT:"File Attachment",
  DOWNLOAD:"Click to Download",
  LOADING_DOWNLOAD:"DOWNLOADING DOCUMENT...",
  CLIENT_RELATIVEPATH_KEY:"USERPIC",
  LEAVE_RELATIVEPATH_KEY:"LVMGT",
  REIMBURSEMENT_RELATIVEPATH_KEY:"REIMEXP",
  ASSOCIATE_RELATIVEPATH_KEY:"EIONBREC",
  ALERT:"Alert",
  NO_FILE:"File is not Available",
  NOT_ABLE_TO_DOWNLOAD:"Not able to download the file.",
  NA:"Not Applicable",
  NOT_AVAILABLE: "Not Available",
  COMMENTS: "Comments",
  CANCELLED: "Cancelled",
};
