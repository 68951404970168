import { Icon, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import DropDown from "components/Dropdown";
import { AssociatesReimbursementDetails } from "models/reimbursement.data";
import { TableData } from "./type";
import moment from "moment";
import { SummaryDetails } from "models/dashboard.data";
import text from "text";

const actions: any[] = [
  {
    key: text.APPROVE,
    value: text.APPROVE,
    status: text.AUTHORIZED,
  },
  {
    key: text.REJECT,
    value: text.REJECT,
    status: text.REJECTED,
  },
];

const getActions = (action: any) => {
  const actionList = actions.filter(item => {
    return item.status !== action;
  });
  return actionList;
};

const isActionAlreadyTaken = (action: string) => {
  let isActionTaken = false;
  for (let i = 0; i < actions.length; i++) {
    if (actions[i].status === action) {
      isActionTaken = true;
    }
  }
  return isActionTaken;
};

export function manipulateData(data: AssociatesReimbursementDetails[]) {
  const manipulatedData: TableData[] = [];
  data.map((innerRow: AssociatesReimbursementDetails, innerIndex: number) => {
    const helperArray: TableData = {
      key: 0,
      associateName: "",
      expenseDescription: "",
      expenseId: "",
      date: "",
      requestedAmount: 0,
      authorizedAmount: 0,
      exception: "",
      status: "",
      action: "",
      file:"",
      Employeecode:"",
      Requestnumber:"",
    };

    const date = moment(innerRow.Requesteddate)
      .format("Do MMM YY")
      .split(" ");

    const getDate =
      date[0] + " " + date[1].slice(0, 3).toUpperCase() + "'" + date[2];

    helperArray.key = innerIndex;
    helperArray.associateName = (innerRow.Employeename as string).replace(
      /\w\S*/g,
      function(txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
    helperArray.expenseDescription = innerRow.Expensecategorydescription as string;
    helperArray.date = getDate;
    helperArray.expenseId = innerRow.Expensecategorycode as string;
    helperArray.requestedAmount = innerRow.Expenseamount as number;
    helperArray.authorizedAmount = innerRow.Authorizedamount as number;
    helperArray.exception = innerRow.Rejectionremarks as string;
    helperArray.status = (innerRow.Statusdescription as string).split(" ")[0];
    helperArray.Employeecode=innerRow.Employeecode as string;
    helperArray.Requestnumber=innerRow.Requestnumber as string;
    helperArray.file=text.DOWNLOAD;
    manipulatedData.push(helperArray);
  });
  return manipulatedData;
}

export function column(
  actionTaken: (value: string, data: TableData) => void,
  downloadFile: (index:number|undefined)=> void
) {
  const fixed = window.innerWidth > 600 ? "right" : "";
  return [
    {
      title: text.ASSOCIATE_NAME,
      key: "associateName",
      dataIndex: "associateName",
      render: (associateName: string) => {
        return <Row type="flex" justify="start">{associateName}</Row>;
      },
    },
    {
      title: text.EXPENSE_DESCRIPTION,
      key: "expenseDescription",
      dataIndex: "expenseDescription",
      align:"center",
      render: (expenseDescription: string) => {
        return <Row type="flex" justify="center">{expenseDescription}</Row>;
      },
    },
    {
      title: text.FILE_ATTACHMENT,
      key: "file",
      dataIndex: "file",
      align:"center",
      render: (file:string,row: TableData) => {
        return <Row type="flex" justify="center"><a className="c-info bg-white" style={{textDecoration:"underline"}} onClick={()=> downloadFile(row.key)}>{file}</a></Row>;
      },
    },
    {
      title: text.DATE,
      key: "date",
      dataIndex: "date",
      align:"center",
      render: (date: string) => {
        return <Row type="flex" justify="center">{date}</Row>;
      },
    },
    {
      title: text.REQUESTED_AMOUNT,
      key: "requestedAmount",
      dataIndex: "requestedAmount",
      align:"center",
      render: (requestedAmount: number) => {
        return (
          <Row
          type="flex" justify="center">{`${text.RUPEE_SYMBOL} ${requestedAmount}`}</Row>
        );
      },
    },
    {
      title: text.REMARKS,
      key: "exception",
      dataIndex: "exception",
      align:"center",
      render: (exception: string) => {
        if (exception != null) {
          return (
            <Row type="flex" justify="start">
            <Tooltip placement="topLeft" title={exception}>
              <Icon type="exclamation-circle" className="pl-3 c-secondary" />
            </Tooltip>
            </Row>
          );
        } else {
          return <Row type="flex" justify="start"><Icon type="minus" className="pl-3 c-secondary" /></Row>;
        }
      },
    },
    {
      title: text.STATUS,
      key: "status",
      dataIndex: "status",
      align:"center",
      render: (status: string) => {
        let colour = "#00b6ef";
        if (status === text.PENDING) {
          colour = "#fbb517";
        } else if (status === text.AUTHORIZED) {
          colour = "#02af9b";
        } else if (status === text.REJECTED) {
          colour = "#ee2e22";
        }
        return <Row type="flex" justify="center"><Text style={{ color: colour }}>{status}</Text></Row>;
      },
    },
    {
      title: text.ACTION,
      key: "action",
      dataIndex: "action",
      align:"center",
      fixed,
      render: (action: string, row: any, index: number) => {
        if (row.status === text.PENDING) {
          action = text.PENDING;
        } else if (row.status === text.AUTHORIZED) {
          action = text.AUTHORIZED;
        } else if (row.status === text.REJECTED) {
          action = text.REJECTED;
        } else if (row.status) {
          action = row.status;
        } else {
          action = text.SELECT;
        }

        return (
          <Row type="flex" justify="start">
          <DropDown
            actionAlreadyTaken={isActionAlreadyTaken(row.status)}
            menuList={getActions(row.status)}
            getSelectedValue={(args)=>actionTaken(args,row)}
            dropdownText={action}
            index={index}
          />
          </Row>
        );
      },
    },
  ];
}

export function summary(data: SummaryDetails[]) {
  if (data.length !== 0) {
    overviewData[0].value = data.filter(
      element => element.statusCode === text.ALL.toUpperCase(),
    )[0].recordCount;
    overviewData[1].value = data.filter(
      element => element.statusCode === text.PENDING_CODE,
    )[0].recordCount;
    overviewData[2].value = data.filter(
      element => element.statusCode === text.ACTION_TAKEN_CODE,
    )[0].recordCount;
  }
  return overviewData;
}

export const overviewData: any = [
  {
    title: text.TOTAL_REQUESTS,
    value: 0,
  },
  {
    title: text.ACTION_PENDING,
    value: 0,
  },
  {
    title: text.ACTION_TAKEN,
    value: 0,
  },
];
export const colors: string[] = ["#00b6ef", "#fbb517", "#02af9b"];
