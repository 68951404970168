import moment from "moment";

export const dateFormat="YYYY-MM-DDTHH:mm:ss";

export const defaultClientEndDate = moment().endOf("day");
export const defaultClientStartDate = moment().startOf("day")
  .subtract(3, "months");
export const defaultClientLeaveEndDate = moment().endOf("day")
  .add(3, "months");
export const defaultClientLeaveStartDate = moment().startOf("day")
  .subtract(3, "months")

export const defaultLeaveEndDate = moment().endOf("year");