import React, { useState, useEffect, Fragment } from "react";
import { Row, Table, Modal, Button, Empty } from "antd";
import { RejectPopUp } from "components/RejectPopUp";
import { timesheetImage } from "AssetHelper";
import { style } from "styles/Fonts";
import {
  column,
  overviewData,
  colors,
  manipulateData,
  summary,
} from "./Helper";
import { TimesheetFilterInterface, TableData, PopUP, BulkPopUP } from "./type";
import {
  Authrejectrequest,
  getRequestData,
  Attendancedetails,
} from "models/timesheet.data";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { TimesheetActions } from "screens/timesheet/actions";
import OverviewCard from "components/OverviewCard";
import { DashboardActions } from "screens/dashboard/actions";
import moment from "moment";
import Loader from "components/Loader";
import Filters, { IFilterParams } from "components/Filters/Filters";
import { createPostObject } from "./objectFactory";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { FilterInterface, FilterType } from "components/Filter/type";
import TimesheetService from "./TimesheetService";
import text from "text";
import { ModalType } from "components/Modal/type";
import { ModuleType } from "screens/home/store/home/constants";
import { defaultClientEndDate, defaultClientStartDate, dateFormat } from "date";

export default function Timesheet(props: any) {
  const selectedStatus = props.location.search?.split("status=")[1];
  const [filterValue, setFilterValues] = useState<IFilterParams>({
    startDate: undefined,
    endDate: undefined,
    status: selectedStatus || "All",
  });
  const [reason, setReason] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [actionTakenSate, setActionTaken] = useState<string>();
  const [selectedRowKey, setSelectedRowKey] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<TableData[]>([]);
  const [popup, setPopup] = useState<PopUP>({
    visible: false,
    action: "",
    data: {},
  });
  const [bulkPopup, setBulkPopup] = useState<BulkPopUP>({
    visible: false,
    action: "",
    index: 0,
  });

  const { dashboardStore, timesheetStore, contextStore } = useSelector(
    (state: any) => {
      return state;
    },
    shallowEqual
  );

  useEffect(() => {
    getAssociatesData({
      startDate: defaultClientStartDate.format(dateFormat),
      endDate: defaultClientEndDate.format(dateFormat),
      status: filterValue.status || "All",
      actionType: text.GET_TIMESHEET_ACTION,
    });
    getTimesheetSummaryData({
      startDate: defaultClientStartDate.format(dateFormat),
      endDate: defaultClientEndDate.format(dateFormat),
      internalRole: "APR",
    });
  }, []);

  const dispatch = useDispatch();

  const getAssociatesData = (filter: TimesheetFilterInterface) => {
    dispatch(TimesheetActions.getAssociatesTimesheetDetails.started(filter));
  };

  const getTimesheetSummaryData = (requestData: getRequestData) => {
    dispatch(DashboardActions.getTimesheetSummary.started(requestData));
  };

  const approveTimesheets = async (payload: Authrejectrequest[]) => {
    try {
      setShowLoadingModal(true);
      const response = await TimesheetService.approveTimesheet(payload);
      onTimesheetSuccess(payload);
      // dataRender(filterValue as FilterInterface);
      onFilterChange(filterValue);
      setSelectedRowKey([]);
    } catch (err) {
      setShowLoadingModal(false);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const onTimesheetSuccess = (apiRes: Authrejectrequest[]) => {
    const { Action } = apiRes[0];
    const timesheetOrTimesheets =
      apiRes.length > 1 ? text.TIMESHEETS : text.TIMESHEET;
    setShowLoadingModal(false);

    if (Action && Action.toLowerCase() === text.REJECT_TIMESHEET) {
      ModalPopUp({
        type: ModalType.info,
        title: text.REJECTED,
        description: `${text.REJECTED_MESSAGE} ${timesheetOrTimesheets}`,
      });
    } else {
      ModalPopUp({
        type: ModalType.success,
        title: text.APPROVED,
        description: `${text.APPROVED_MESSAGE} ${timesheetOrTimesheets}`,
      });
    }
  };

  const getTimesheetData = () => {
    const data = timesheetStore.associatesTimsheetDetails.data;
    if (searchValue) {
      return data.filter((item: Attendancedetails) => {
        const searchField =
          (item.customOutput1 &&
            typeof item.customOutput1 === "string" &&
            item.customOutput1.toLowerCase()) ||
          "";
        return searchField.indexOf(searchValue.toLowerCase().trim()) > -1;
      });
    }
    return data;
  };

  const approveAction = (value: string, data: TableData) => {
    const postData = createPostObject(text.APPROVE_TIMESHEET, data, reason);
    approveTimesheets(postData);
  };

  const rejectAction = (value: string, data: TableData, reason: string) => {
    const postData = createPostObject(text.REJECT_TIMESHEET, data, reason);
    approveTimesheets(postData);
    setPopup({ visible: false, action: "", data: {} });
  };

  const rejectionMessage = (value: string, data: TableData) => {
    setPopup({ visible: true, action: value, data });
  };
  const onConfirmAction = (value: string, data: TableData) => {
    if (data !== undefined) {
      if (value === text.APPROVE) {
        approveAction(value, data);
      } else {
        rejectionMessage(value, data);
      }
    }
  };

  const actionTaken = (value: string, data: TableData) => {
    setActionTaken(value);
    const description = `You want to ${value} this Attendance`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmAction,
      value,
      data,
    });
  };

  // const dataRender = (value: FilterInterface) => {
  //   setSelectedRowKey([]);
  //   setCurrentPage(1);
  //   setFilterValues(value);
  //   getAssociatesData({
  //     actionType: text.GET_TIMESHEET_ACTION,
  //     status: value.status,
  //     startDate: value.startDate,
  //     endDate: value.endDate,
  //   });
  //   const requestData = {
  //     internalRole: text.APR,
  //     endDate: value.endDate,
  //     startDate: value.startDate
  //   };
  //   getTimesheetSummaryData(requestData);
  // };

  const getSearchValue = (value: string) => {
    setSelectedRowKey([]);
    setSearchValue(value);
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const OnChangeRowSelection = (
    value: any,
    setSelectedRowData: TableData[]
  ) => {
    setSelectedRow(setSelectedRowData);
    setSelectedRowKey(value);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: OnChangeRowSelection,
      getCheckboxProps: (record: any) => ({
        disabled: record.status !== "Pending",
      }),
    };
  };

  const bulkApproveAction = (value: string, index: number) => {
    const postData: Authrejectrequest[] = [];
    for (const row of selectedRow) {
      postData.push(createPostObject(text.APPROVE_TIMESHEET, row, reason)[0]);
    }
    approveTimesheets(postData);
  };

  const bulkRejectAction = (value: string, index: number, reason: string) => {
    const postData: Authrejectrequest[] = [];
    for (const row of selectedRow) {
      postData.push(createPostObject(text.REJECT_TIMESHEET, row, reason)[0]);
    }
    approveTimesheets(postData);
    setBulkPopup({ visible: false, action: "", index: 0 });
  };

  const bulkRejectionMessage = (value: string, index: number) => {
    setBulkPopup({ visible: true, action: value, index });
  };
  const onConfirmBulkAction = (value: string, index: number | undefined) => {
    if (index !== undefined) {
      if (value === text.APPROVE) {
        bulkApproveAction(value, index);
      } else {
        bulkRejectionMessage(value, index);
      }
    }
  };

  const bulkActionTaken = (value: string, index: number | undefined) => {
    setActionTaken(value);
    const description = `You want to ${value} this Attendance`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmBulkAction,
      value,
      data: index,
    });
  };

  const getRequestData = (filters: IFilterParams) => {
    let filterStartDate = filters.startDate;
    let filterEndDate = filters.endDate;
    if (filterStartDate && filterEndDate) {
      if (filterStartDate > filterEndDate) {
        filterEndDate = filterStartDate.endOf("year");
      }
    } else if (filterStartDate && !filterEndDate) {
      if (filterStartDate > defaultClientEndDate) {
        filterEndDate = filterStartDate.endOf("year");
      }
    }
    if (filterStartDate) {
      filterStartDate = filterStartDate.startOf("day");
    }
    if (filterEndDate) {
      filterEndDate = filterEndDate.endOf("day");
    }
    const startDate = (filterStartDate || defaultClientStartDate).format(
      dateFormat
    );
    const endDate = (filterEndDate || defaultClientEndDate).format(dateFormat);
    return { startDate, endDate };
  };

  const onFilterChange = (filters: IFilterParams) => {
    const { startDate, endDate } = getRequestData(filters);
    getAssociatesData({
      startDate: startDate,
      endDate: endDate,
      status: filters.status || "All",
      actionType: text.GET_TIMESHEET_ACTION,
    });
    getTimesheetSummaryData({
      startDate,
      endDate,
      internalRole: "APR",
    });
    setSelectedRowKey([]);
    setCurrentPage(1);
    setSearchValue("");
    setFilterValues({
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status || "All",
    });
  };

  if (!contextStore.allowedActivities[ModuleType.ATTENDANCE])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Fragment>
      <RejectPopUp
        visible={popup.visible}
        onReject={rejectAction}
        value={popup.action}
        data={popup.data}
        onCancel={() => {
          setPopup({ visible: false, action: "", data: {} });
        }}
      />
      <RejectPopUp
        visible={bulkPopup.visible}
        onReject={bulkRejectAction}
        value={bulkPopup.action}
        data={bulkPopup.index}
        onCancel={() => {
          setBulkPopup({ visible: false, action: "", index: 0 });
        }}
      />
      <Modal visible={showLoadingModal} footer={null} closable={false}>
        <Loader />
        <Row className="c-primary" type="flex" justify="center">
          {`${actionTakenSate?.toUpperCase()} ${text.ATTENDANCE.toLocaleUpperCase()}...`}
        </Row>
      </Modal>
      <Row className="p-6">
        <Row className="pb-2" id="heading">
          <img src={timesheetImage} className="h-6" />
          &nbsp;
          <span style={style.large} className="ff-secondary">
            {" "}
            {text.ATTENDANCE}
          </span>
        </Row>
        <Row className="pb-0 pb-md-6" id="overviewCard">
          <OverviewCard
            overviewList={summary(
              dashboardStore.dashboardSummary.timesheetSummary.data
            )}
            colorList={colors}
            isLoading={dashboardStore.loading}
          />
        </Row>
        <Filters
          filterValues={filterValue}
          searchName={searchValue}
          onChangeFilters={onFilterChange}
          onSearchText={setSearchValue}
          maxDate={moment()}
        />
        <Row id="table">
          {timesheetStore.loading ? (
            <Loader />
          ) : timesheetStore.associatesTimsheetDetails.data ? (
            <div>
              {selectedRowKey.length > 0 && (
                <Row className="py-5 w-100" type="flex" justify="end">
                  <Button
                    className="bg-success c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.APPROVE, 0)}>
                    {text.APPROVE}
                  </Button>
                  <Button
                    title={text.REJECT}
                    className="bg-primary c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.REJECT, 0)}>
                    {text.REJECT}
                  </Button>
                </Row>
              )}
              <Table
                // @ts-ignore
                columns={column(actionTaken)}
                dataSource={manipulateData(getTimesheetData())}
                scroll={{ x: true }}
                pagination={{
                  pageSize: 5,
                  current: currentPage,
                  onChange: changePage,
                }}
                rowSelection={rowSelection()}
              />
            </div>
          ) : (
            <Row className="bg-white w-100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Row>
      </Row>
    </Fragment>
  );
}
