import React, { useState, useEffect } from "react";
import { Input, Row, Icon } from "antd";

export default function Search(props: {
  placeholder: string;
  getSearchedValue: (searchedValue: any) => void;
  data: any[];
}) {
  const { placeholder, getSearchedValue, data } = props;
  const [searchedValue, setSearchedValue] = useState(Array<any>());

  const handleSearch = (value: any) => {
    getSearchedValue(value.currentTarget.value);
  };

  useEffect(() => {
    setSearchedValue(data);
  }, [searchedValue, data]);

  return (
    <Row type="flex" justify="center" align="middle" className="m-2">
      <Input
        placeholder={placeholder}
        onPressEnter={handleSearch}
        prefix={<Icon type="search" />}
        onInput={event => {
          handleSearch(event);
        }}
      />
    </Row>
  );
}
