export enum ModuleType {
  ATTENDANCE = "attendance",
  LEAVE = "leave",
  REIMBURSEMENT = "reimbursement",
}

export const roleMappingCli: { [key: string]: string } = {
  hrmstbaprvtme: ModuleType.ATTENDANCE,
  lvserautlapp: ModuleType.LEAVE,
  rtessautaucl: ModuleType.REIMBURSEMENT,
};

export const defaultAllowedActivities = {
  [`${ModuleType.ATTENDANCE}`]: false,
  [`${ModuleType.LEAVE}`]: false,
  [`${ModuleType.REIMBURSEMENT}`]: false,
};
