export interface ICustomImageProps {
  file: string;
  relativePath: string;
  type?: string;
  width: string;
}

export enum ImageViewType {
  ROUND = "round",
  SQUARED = "squared",
}
