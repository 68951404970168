import React, { useState, useEffect, useRef, Fragment } from "react";
import { Row, Table, Modal, Button, Empty } from "antd";
import { RejectPopUp } from "components/RejectPopUp";
import { leaveImage } from "AssetHelper";
import { style } from "styles/Fonts";
import OverviewCard from "components/OverviewCard";
import moment from "moment";
import {
  column,
  overviewData,
  colors,
  manipulateData,
  getSession,
} from "./Helper";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { LeaveActions } from "./actions";
import { Approverrequest, LeaveList } from "models/leave.data";
import { DashboardActions } from "screens/dashboard/actions";
import Loader from "components/Loader";
import Filters, { IFilterParams } from "components/Filters/Filters";
import { createPostObject } from "./objectFactory";
import { FilterInterface, FilterType } from "components/Filter/type";
import leaveService from "./LeaveService";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import text from "text";
import { getRequestData } from "models/timesheet.data";
import { PopUP, FiltersInterface, TableData, PopUPBulk } from "./type";
import { summary } from "screens/timesheet/Helper";
import { ModalType } from "components/Modal/type";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";
import { getPDFURI } from "common/Utils.tsx/Download";
import { getRelativePathForAssociate } from "common/Utils.tsx/GetRelativePath";
import {
  defaultClientLeaveStartDate,
  defaultClientLeaveEndDate,
  dateFormat,
} from "date";

export default function Leave(props: any) {
  const selectedStatus = props.location.search?.split("status=")[1];
  const [reason, setReason] = useState();
  const [filterValue, setFilterValues] = useState<IFilterParams>({
    startDate: undefined,
    endDate: undefined,
    status: selectedStatus,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [actionTakenSate, setActionTaken] = useState<string>();
  const [selectedRowKey, setSelectedRowKey] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<TableData[]>([]);
  const [loadingModalMsg, setLoadingModalMsg] = useState("");
  const [popup, setPopup] = useState<PopUP>({
    visible: false,
    action: "",
    data: {},
  });
  const [bulkPopup, setBulkPopup] = useState<PopUPBulk>({
    visible: false,
    action: "",
    index: 0,
  });

  const { dashboardStore, leaveStore, contextStore } = useSelector(
    (state: ApplicationState) => {
      return state;
    }
  );

  useEffect(() => {
    getAssociatesData({
      fromDateSearch: defaultClientLeaveStartDate.format(dateFormat),
      toDateSearch: defaultClientLeaveEndDate.format(dateFormat),
      statusCodeSearch: filterValue.status || "All",
    });
    getLeaveSummaryData({
      startDate: defaultClientLeaveStartDate.format(dateFormat),
      endDate: defaultClientLeaveEndDate.format(dateFormat),
      internalRole: "APR",
    });
  }, []);

  const dispatch = useDispatch();

  const getAssociatesData = (filter: FiltersInterface) => {
    dispatch(LeaveActions.getAssociatesLeaveDetails.started(filter));
  };

  const getLeaveSummaryData = (requestData: getRequestData) => {
    dispatch(DashboardActions.getLeaveSummary.started(requestData));
  };

  const approveLeaves = async (payload: Approverrequest[]) => {
    try {
      setLoadingModalMsg(
        `${payload[0].Actiontype?.toUpperCase()} ${text.LEAVE.toUpperCase()}...`
      );
      setShowLoadingModal(true);
      const response = await leaveService.approveLeave(payload);
      onLeaveSuccess(payload);
      // dataRender(filterValue as FilterInterface);
      onFilterChange(filterValue);
      setSelectedRowKey([]);
    } catch (err) {
      setShowLoadingModal(false);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const downloadFile = async (file: string) => {
    try {
      if (file) {
        setLoadingModalMsg(text.LOADING_DOWNLOAD);
        setShowLoadingModal(true);
        await getPDFURI(
          file,
          getRelativePathForAssociate(
            contextStore.relativePath.data,
            text.LEAVE_RELATIVEPATH_KEY
          )
        ).then(response => {
          if (response !== 200) {
            ModalPopUp({
              type: ModalType.error,
              title: text.ALERT.toUpperCase(),
              description: text.NOT_ABLE_TO_DOWNLOAD,
            });
          }
        });
      } else {
        ModalPopUp({
          type: ModalType.error,
          title: text.ALERT.toUpperCase(),
          description: text.NO_FILE,
        });
      }
    } catch (err) {
      setShowLoadingModal(false);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const onLeaveSuccess = (apiRes: Approverrequest[]) => {
    const { Actiontype } = apiRes[0];
    const leaveOrLeaves = apiRes.length > 1 ? text.LEAVES : text.LEAVE;
    setShowLoadingModal(false);

    if (Actiontype === text.REJECT) {
      ModalPopUp({
        type: ModalType.info,
        title: text.REJECTED,
        description: `${text.REJECTED_MESSAGE} ${leaveOrLeaves}`,
      });
    } else {
      ModalPopUp({
        type: ModalType.success,
        title: text.APPROVED,
        description: `${text.APPROVED_MESSAGE} ${leaveOrLeaves}`,
      });
    }
  };

  const getLeaveData = () => {
    const { ApproverList } = leaveStore.associatesLeaveDetails.data;
    if (searchValue) {
      return ApproverList.filter((item: LeaveList) => {
        const searchField =
          (item.requestorEmployeeName &&
            typeof item.requestorEmployeeName === "string" &&
            item.requestorEmployeeName.toLowerCase()) ||
          "";
        return searchField.indexOf(searchValue.toLowerCase().trim()) > -1;
      });
    }
    return ApproverList;
  };

  const approveAction = (value: string, data: TableData) => {
    const postData = createPostObject(value, data, reason);
    approveLeaves(postData);
  };

  const rejectAction = (value: string, data: TableData, reason: string) => {
    const postData = createPostObject(value, data, reason);
    approveLeaves(postData);
    setPopup({ visible: false, action: "", data: {} });
  };

  const rejectionMessage = (value: string, data: TableData) => {
    setPopup({ visible: true, action: value, data });
  };

  const onConfirmAction = (value: string, data: TableData) => {
    if (data !== undefined) {
      if (value === text.APPROVE) {
        approveAction(value, data);
      } else {
        rejectionMessage(value, data);
      }
    }
  };

  const actionTaken = (value: string, data: TableData) => {
    setActionTaken(value);
    const description = `You want to ${value} Leave`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmAction,
      value,
      data,
    });
  };

  // const dataRender = (value: FilterInterface) => {
  //   setSelectedRowKey([]);
  //   setCurrentPage(1);
  //   // setFilterValues(value);
  //   getAssociatesData({
  //     fromDateSearch: value.startDate,
  //     toDateSearch: value.endDate,
  //     statusCodeSearch: value.status,
  //   });
  //   const requestData = {
  //     internalRole: text.APR,
  //     endDate: value.endDate,
  //     startDate: value.startDate,
  //   };
  //   getLeaveSummaryData(requestData);
  // };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const getSearchValue = (value: string) => {
    setSelectedRowKey([]);
    setSearchValue(value);
  };

  const OnChangeRowSelection = (value: any, selectedRowsData: TableData[]) => {
    setSelectedRow(selectedRowsData);
    setSelectedRowKey(value);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: OnChangeRowSelection,
      getCheckboxProps: (record: any) => {
        return {
          disabled: record.statusCode === "AUTH" || record.statusCode === "REJE" || record.statusCode === "CAN" ,
        };
      },
    };
  };

  const bulkApproveAction = (value: string, index: number) => {
    const postData: Approverrequest[] = [];
    for (const rowData of selectedRow) {
      postData.push(createPostObject(value, rowData, reason)[0]);
    }
    approveLeaves(postData);
  };

  const bulkRejectAction = (value: string, data: any, reason: string) => {
    const postData: Approverrequest[] = [];
    for (const rowData of selectedRow) {
      postData.push(createPostObject(value, rowData, reason)[0]);
    }
    approveLeaves(postData);
    setBulkPopup({ visible: false, action: "", index: 0 });
  };

  const bulkRejectionMessage = (value: string, index: number) => {
    setBulkPopup({ visible: true, action: value, index });
  };

  const onConfirmBulkAction = (value: string, index: number | undefined) => {
    if (index !== undefined) {
      if (value === text.APPROVE) {
        bulkApproveAction(value, index);
      } else {
        bulkRejectionMessage(value, index);
      }
    }
  };

  const bulkActionTaken = (value: string, index: number | undefined) => {
    setActionTaken(value);
    const description = `You want to ${value} Leaves`;
    ModalPopUp({
      type: ModalType.confirm,
      title: text.ARE_YOU_SURE,
      description,
      onSubmit: onConfirmBulkAction,
      value,
      data: index,
    });
  };

  const getRequestData = (filters: IFilterParams) => {
    let filterStartDate = filters.startDate;
    let filterEndDate = filters.endDate;
    if (filterStartDate && filterEndDate) {
      if (filterStartDate > filterEndDate) {
        const tempFliterStartDate = filterStartDate.clone();
        filterEndDate = tempFliterStartDate.endOf("year");
      }
    } else if (filterStartDate && !filterEndDate) {
      if (filterStartDate > defaultClientLeaveEndDate) {
        const tempFliterStartDate = filterStartDate.clone();
        filterEndDate = tempFliterStartDate.endOf("year");
      }
    }
    if (filterStartDate) {
      filterStartDate = filterStartDate.startOf("day");
    }
    if (filterEndDate) {
      filterEndDate = filterEndDate.endOf("day");
    }
    const startDate = (filterStartDate || defaultClientLeaveStartDate).format(
      dateFormat
    );
    const endDate = (filterEndDate || defaultClientLeaveEndDate).format(
      dateFormat
    );
    return { startDate, endDate };
  };

  const onFilterChange = (filters: IFilterParams) => {
    const { startDate, endDate } = getRequestData(filters);
    getAssociatesData({
      fromDateSearch: startDate,
      toDateSearch: endDate,
      statusCodeSearch: filters.status || "All",
    });
    getLeaveSummaryData({
      startDate,
      endDate,
      internalRole: "APR",
    });
    setSelectedRowKey([]);
    setCurrentPage(1);
    setSearchValue("");
    setFilterValues({
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status || "All",
    });
  };

  if (!contextStore.allowedActivities[ModuleType.LEAVE])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Fragment>
      <RejectPopUp
        visible={popup.visible}
        onReject={rejectAction}
        value={popup.action}
        data={popup.data}
        onCancel={() => {
          setPopup({ visible: false, action: "", data: {} });
        }}
      />
      <RejectPopUp
        visible={bulkPopup.visible}
        onReject={bulkRejectAction}
        value={bulkPopup.action}
        data={bulkPopup.index}
        onCancel={() => {
          setBulkPopup({ visible: false, action: "", index: 0 });
        }}
      />
      <Modal visible={showLoadingModal} footer={null} closable={false}>
        <Loader />
        <Row className="c-primary" type="flex" justify="center">
          {loadingModalMsg}
        </Row>
      </Modal>
      <Row className="p-6" id="leave">
        <Row className="pb-2" id="heading">
          <img src={leaveImage} className="h-6" />
          &nbsp;
          <span style={style.large} className="ff-secondary">
            {" "}
            {text.LEAVE}
          </span>
        </Row>
        <Row className="pb-0 pb-md-6" id="overviewCard">
          <OverviewCard
            overviewList={summary(
              dashboardStore.dashboardSummary.leaveSummary.data
            )}
            colorList={colors}
            isLoading={dashboardStore.loading}
          />
        </Row>
        <Filters
          filterValues={filterValue}
          searchName={searchValue}
          onChangeFilters={onFilterChange}
          onSearchText={setSearchValue}
        />
        <Row id="table">
          {leaveStore.loading ? (
            <Loader />
          ) : leaveStore.associatesLeaveDetails.data.ApproverList ? (
            // @ts-ignore
            <div>
              {selectedRowKey.length > 0 && (
                <Row className="py-5 w-100" type="flex" justify="end">
                  <Button
                    className="bg-success c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.APPROVE, 0)}>
                    {text.APPROVE}
                  </Button>
                  <Button
                    title={text.REJECT}
                    className="bg-primary c-white px-1 mx-2 w-10"
                    onClick={() => bulkActionTaken(text.REJECT, 0)}>
                    {text.REJECT}
                  </Button>
                </Row>
              )}
              <Table
                // @ts-ignore
                columns={column(actionTaken, downloadFile)}
                dataSource={
                  leaveStore.associatesLeaveDetails.data.ApproverList.length
                    ? manipulateData(getLeaveData())
                    : []
                }
                scroll={{ x: true }}
                pagination={{
                  pageSize: 5,
                  current: currentPage,
                  onChange: changePage,
                }}
                rowSelection={rowSelection()}
              />
            </div>
          ) : (
            <Row className="bg-white w-100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Row>
      </Row>
    </Fragment>
  );
}
