import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { ReporteesList } from "models/associate.data";

export class AssociateService {
  public getRepoteesList(): Promise<ReporteesList> | ReporteesList {
    const service: ApiService = new ApiService(EndPoints.repotees);
    return service.get({});
  }
}

export default new AssociateService();
